import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ClientInfo, clientInfoAtom } from '../recoilAtoms';

import { Box, Button, Typography, Switch, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { styles } from './profile.style';

import prevlight from '@common/assets/image/prevlight.svg';

import { isChromeExtension, setStorage } from '@services/extension/funcs';
import { bubbleUrl } from '@services/api/baseapi';
import { apiCustomerinfoGetmeta, apiCustomerinfoNSFW } from '@services/api/customerinfo';


const Profile: React.FC = () => {
  const classes = makeStyles(styles)();
  const navigate = useNavigate();
  const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
  const [subscription, setSubscription] = useState('');
  const planMapping: Record<string, string> = {
    Free: 'Free / 15tokens (3 photos) per day',
    Basic: 'Basic / 500tokens (100 photos) per month',
    Ultimate: 'Ultimate / 2000tokens (400 photos) per month',
  };

  const [loading, setLoading] = useState(true); // 첫 랜딩 로딩써클
  useEffect(() => {
    const transformedPlan = planMapping[clientInfo.plan] || clientInfo.plan;
    const targetDateStr = clientInfo.paydue;
    if (targetDateStr) {
      const today = new Date();
      const targetDate = new Date(targetDateStr);
      const timeDifference: number = targetDate.getTime() - today.getTime();
      const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));
      setSubscription(transformedPlan + ` / ${daysLeft} days left`);
    } else {
      setSubscription(transformedPlan);
    }

    setTimeout(() => setLoading(false), 500);
  });


  const handleSetStream = () => {
    navigate('/stream');
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    // UI 즉시 업데이트
    setClientInfo((prev) => ({
      ...prev,
      nsfw: isChecked,
    }));

    // 비동기 작업 수행
    const fetchCustomerInfo = async () => {
      await apiCustomerinfoNSFW(clientInfo.sid, isChecked);
      const customerinfo = await apiCustomerinfoGetmeta(clientInfo.sid);
      if (customerinfo) {
        const clientInfoResult: ClientInfo = {
          sid: clientInfo.sid,
          email: customerinfo.email,
          plan: customerinfo.plan,
          nsfw: customerinfo.nsfw,
          token: customerinfo.usagelimit,
          paymeta: customerinfo.paymeta,
          paydue: customerinfo.paydue,
        };
        setClientInfo(clientInfoResult);
      }
    };

    fetchCustomerInfo();
  };

  const handleUpgrade = () => {
    window.location.href = `https://patreon.com/fusioai`
  }

  const handleResetpw = () => {
    window.location.href = `${bubbleUrl}/reset_pw`
  }

  const handleLogout = async () => {
    if (isChromeExtension()) {
      chrome.storage.local.set({ 'fusio_sid': 'None', 'fusio_server_url': 'None' }, () => {
      });
    } else {
      setStorage({ 'fusio_sid': 'None', 'fusio_server_url': 'None' });
    }
  };


  if (loading) {
    return (
      <Box className={classes.div_first_root}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={44} style={{ color: '#D84E98' }} />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.div_first_root}>
      <Box className={classes.div_first}>
        <Box className={classes.prev_wrap} onClick={handleSetStream}>
          <img
            src={prevlight}
            alt="Selected"
            style={{ maxHeight: '32px', maxWidth: '32px' }}
          />
        </Box>
        <Box className={classes.title_box} >
          <Typography className={classes.title}>Profile Settings </Typography>
        </Box>
        <Box className={classes.text_box} >
          <Box className={classes.text_wrap} >
            <Typography className={classes.text}>E-mail </Typography>
          </Box>
          <Box className={classes.info_box} >
            <Typography className={classes.info}>{clientInfo.email}</Typography>
            <Button
              className={classes.emailbutton}
              onClick={() => handleLogout()}
              sx={{
                textTransform: 'none',
              }}
            >
              Log Out
            </Button>
          </Box>
        </Box>
        <Box className={classes.text_box} >
          <Box className={classes.text_wrap} >
            <Typography className={classes.text}>Subscription </Typography>
          </Box>
          <Box className={classes.info_box} >
            <Typography className={classes.info}>{subscription}</Typography>
            <Button
              className={classes.upgradebutton}
              onClick={() => handleUpgrade()}
              sx={{
                textTransform: 'none',
              }}
            >
              Upgrade
            </Button>
          </Box>
        </Box>
        <Box className={classes.text_box} >
          <Box className={classes.text_wrap} >
            <Typography className={classes.text}>NSFW</Typography>
            <Typography
              className={`${classes.status} ${clientInfo.nsfw ? classes.enabled : classes.disabled
                }`}
            >
              {clientInfo.nsfw ? 'Enabled' : 'Disabled'}
            </Typography>
          </Box>
          <Box className={classes.info_box} >
            <Typography className={classes.info}>Enable this switch to allow the generation of <br />NSFW (Not Safe For Work) images</Typography>
            <Switch
              className={classes.toggleButton}
              checked={clientInfo.nsfw} // 상태에 따라 UI 업데이트
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
        <Box className={classes.button_box} >
          <Button
            className={classes.button}
            onClick={() => handleLogout()}
            sx={{
              textTransform: 'none',
            }}
          >
            Log Out
          </Button>
          <Button
            className={classes.button}
            onClick={() => handleResetpw()}
            sx={{
              textTransform: 'none',
            }}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { Profile };
