import { createAxiosInstance, config } from './baseapi';

interface ImageitemGetmeta {
  sid: string;
  chatid: string;
  passwd: string;
}

interface ImageitemResponse {
  id: number;
  chatid: string;
  content: string;
  prompt: string;
  llmres: string;
  img: string;
  idx: number;
  msgid: string;
}

async function apiImageitemGetmeta(sid: string, chatid: string): Promise<ImageitemResponse[] | null> {
  const data: ImageitemGetmeta = {
    sid: sid,
    chatid: chatid,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<ImageitemResponse[]>('/api/imageitem-getmeta', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}


interface ImageitemDelmsgid {
  msgid: string;
  passwd: string;
}


async function apiImageitemDelmsgid(msgid: string): Promise<void> {
  const data: ImageitemDelmsgid = {
    msgid: msgid,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/imageitem-delmsgid', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}
export { apiImageitemGetmeta, apiImageitemDelmsgid, ImageitemResponse };


