import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ChatlistResponse, apiChatlistDel } from '@services/api/chatlist';
import { apishareddataGetlist } from '@services/api/shareddata';
import { apisharedcharacter, sharedcharacterResponse } from '@services/api/sharedcharacter';

// 전역 상태 관리 (Recoil Atoms)
import { ChatInfo, clientInfoAtom } from '../recoilAtoms';

// 로컬 스타일 및 이미지 파일
import { styles } from './sharelist.style';

// 설정 파일
import { config } from '@services/api/baseapi';

// 기타 로컬 컴포넌트
import { Logging } from '@services/api/hosterlog';


const useStyles = makeStyles(styles);

const Sharelist: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
    const [sharedCharacter, setSharedCharacter] = useState<sharedcharacterResponse[]>([]);
    const [chatList, setChatList] = useState<{ chatinfo: ChatInfo; dataid: string }[]>([]);
    const [loading, setLoading] = useState(true);
    const [isSharedCharacter, setIsSharedCharacter] = useState(true);

    useEffect(() => {
        const fetchCustomerInfo = async () => {
            try {
                const data = await apisharedcharacter();
                if (Array.isArray(data)) {
                    setSharedCharacter(data)
                }
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerInfo();
        Logging('', 'visit', 'sharelist', '')

        return () => {
            setChatList([]);
        };
    }, [clientInfo.sid]);


    const handleCharacterClick = (charactername: string, domain: string) => {
        const fetchCustomerInfo = async () => {
            try {
                const data = await apishareddataGetlist(charactername, domain);
                if (Array.isArray(data)) {
                    const loadChatInfo = data.map(item => ({
                        chatinfo: JSON.parse(item.chatinfo),
                        dataid: item.dataid,
                    }));
                    setChatList(loadChatInfo);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerInfo();
        setIsSharedCharacter(false)
    };

    const handleChatClick = (dataid: string) => {
        navigate(`/share?dataid=${dataid}`);
    };

    return (
        <Box className={classes.root}>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress size={44} style={{ color: '#D84E98' }} />
                </Box>
            ) : isSharedCharacter ? (
                <Box className={classes.root_main}>
                    <Typography className={classes.title}>{`Characters`}</Typography>
                    {sharedCharacter.map((character, index) => (
                        <Box
                            className={classes.chat_list_item}
                            key={index}
                            onClick={() => handleCharacterClick(character.charactername, character.domain)}
                        >
                            <Box className={classes.chat_img_box}>
                                <img
                                    src={`${config.baseurl}/api/images/${character.img}`}
                                    alt={`${character.img}의 이미지`}
                                    className={classes.chat_img}
                                />
                                <Box className={classes.chat_desc}>
                                    <Typography className={classes.chat_name}>Move to {character.charactername}</Typography>
                                    <Typography className={classes.chat_num}>{character.domain}</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.chat_buttons}>
                                <Button
                                    className={classes.delete_button}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Edit Appearance
                                </Button>
                                <Button
                                    className={classes.delete_button}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box className={classes.root_main}>
                    <Typography className={classes.title}>{`${chatList.length} Shared chats`}</Typography>
                    {chatList.map((chatInfo, index) => (
                        <Box
                            className={classes.chat_list_item}
                            key={index}
                            onClick={() => handleChatClick(chatInfo.dataid)}
                        >
                            <Box className={classes.chat_img_box}>
                                <img
                                    src={`${config.baseurl}/api/images/${chatInfo.chatinfo.img}`}
                                    alt={`${chatInfo.chatinfo.img}의 이미지`}
                                    className={classes.chat_img}
                                />
                                <Box className={classes.chat_desc}>
                                    <Typography className={classes.chat_name}>Move to {chatInfo.chatinfo.charactername}</Typography>
                                    <Typography className={classes.chat_num}>{chatInfo.chatinfo.domain}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.chat_buttons}>
                                <Button
                                    className={classes.delete_button}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Edit Appearance
                                </Button>
                                <Button
                                    className={classes.delete_button}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};



export { Sharelist };
