import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography, Switch, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { styles } from './login.style';

import FusioAI from '@common/assets/image/FusioAI.svg';

import { getStorage } from '@services/extension/funcs';
import { bubbleUrl } from '@services/api/baseapi';

const Login: React.FC = () => {
  const classes = makeStyles(styles)();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // 첫 랜딩 로딩써클

  useEffect(() => {
    setTimeout(() => setLoading(false), 500); // 0.5초 로딩 처리
  });

  useEffect(() => {
    const initializeSid = async () => {
      const result = await getStorage(['fusio_sid']);
      const currentSid = result.fusio_sid || 'None';
      if (currentSid !== 'None') {
        navigate('/');
      };
    }
    initializeSid();
  }, []);

  const handleGotologin = () => {
    window.location.href = `${bubbleUrl}/login`
    // if (isChromeExtension()) {
    //   chrome.storage.local.set({ sid: 'sid12345' }, () => {
    //   });
    // } else {
    //   setStorage({ 'sid': 'sid12345' });
    // }
  }

  const handleSignup = () => {
    window.location.href = `${bubbleUrl}/signup`
    // window.open('https://www.google.com', '_blank'); // 새 탭에서 열기
  }


  if (loading) {
    return (
      <Box className={classes.div_first_root}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={44} style={{ color: '#D84E98' }} />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.div_first_root}>
      <Box className={classes.div_first}>
        <Box className={classes.prev_wrap} >
          <img
            src={FusioAI}
            style={{ width: '132px', maxHeight: '30px', maxWidth: '132px' }}
          />
        </Box>
        <Typography className={classes.title}>Log in</Typography>
        <Button
          className={classes.loginbutton}
          onClick={() => handleGotologin()}
          sx={{
            textTransform: 'none',
          }}
        >
          Go to Login
        </Button>
        <Box className={classes.text_wrap_parent}>
          <Box className={classes.text_wrap}>
            <Typography className={classes.text}>Don't have an account?</Typography>
          </Box>
          <Box className={classes.text_wrap} onClick={() => handleSignup()}>
            <Typography className={classes.text2}>Sign Up</Typography>
          </Box >
        </Box>
      </Box>
    </Box>
  );
};

export { Login };
