import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ClientInfo, clientInfoAtom } from '../recoilAtoms';

import { getStorage } from '@services/extension/funcs';
import { apiCustomerinfoGetmeta } from '@services/api/customerinfo';

import { styles } from './header.style';
import FusioAI from '@common/assets/image/FusioAI.svg';

import lightning from '@common/assets/image/lightning.svg';
import plus from '@common/assets/image/plus.svg';
import infinity from '@common/assets/image/infinity.svg';


const DoubleMHeader: React.FC = () => {
    const classes = makeStyles(styles)();
    const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCustomerInfo = async () => {
            const result = await getStorage(['fusio_sid']);
            const currentSid = result.fusio_sid || 'None';
            if (currentSid === 'None') { return }

            const customerinfo = await apiCustomerinfoGetmeta(currentSid);
            if (customerinfo) {
                const clientInfoResult: ClientInfo = {
                    sid: currentSid,
                    email: customerinfo.email,
                    plan: customerinfo.plan,
                    nsfw: customerinfo.nsfw,
                    token: customerinfo.usagelimit,
                    paymeta: customerinfo.paymeta,
                    paydue: customerinfo.paydue,
                };
                setClientInfo(clientInfoResult);
            }
        };

        fetchCustomerInfo();
    }, []);

    const handleSetSelectedPage = () => {
        navigate('/');
    };

    const handleSetSubscribePage = () => {
        navigate('/subscribe');
    };

    const handleLogin = () => {
        navigate('/login'); // 로그인 페이지로 이동
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.title}>
                <Box className={classes.title_img} onClick={handleSetSelectedPage}>
                    <img
                        src={FusioAI}
                        style={{ width: '154px', height: '40px' }}
                    />
                </Box>
            </Box>
            <Box className={classes.info}>
                {clientInfo.sid.toLowerCase() === 'none'
                    ? (
                        <Box className={classes.info_token} onClick={handleLogin}>
                            <img
                                src={lightning}
                                style={{ width: '10px', height: '20px' }}
                            />
                            <Typography className={classes.info_txt2}>Login</Typography>
                            <img
                                src={plus}
                                style={{ width: '24px', height: '24px' }}
                            />
                        </Box>
                    ) : (
                        <Box className={classes.info_token} onClick={handleSetSubscribePage}>
                            <img
                                src={lightning}
                                style={{ width: '10px', height: '20px' }}
                            />
                            <Typography className={classes.info_txt2}>Token {clientInfo.token}</Typography>
                            <img
                                src={plus}
                                style={{ width: '24px', height: '24px' }}
                            />
                        </Box>
                    )}
            </Box>
        </Box>
    );
};


export { DoubleMHeader };
