import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
    createStyles({
        root: {
            height: '100%',
            padding: '35px 0px 0px 0px',
            backgroundColor: theme.Wcolor.black,
            // border: '1px solid #ddd',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            // borderRight: '1px solid #8E8E93',
        },
        rootrightborder: {
            borderRight: '1px solid #8E8E93',
        },
        back_button: {
            height: '25px',
            width: '25px',
            padding: '0px',
            position: 'fixed',
            top: '77px',
            left: '10px',
            zIndex: 1000,
        },

        root_div: {
            height: `calc(100% - 50px)`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
        },
        main_div: {
            width: '240px',
            height: '146px',
            // borderBottom: '1px solid #8E8E93',
        },
        button: {
            width: '240px',
            height: '32px',
            padding: '0px 0px 0px 20px',
            marginBottom: '10px',
            justifyContent: 'flex-start',
            color: theme.Wcolor.white,
            textTransform: 'none',
            fontSize: 20,
        },
        buttonSelected: {
            backgroundColor: 'rgba(216, 78, 152, 0.2)',
            color: theme.Wcolor.pink,
        },
        second_div: {
            padding: '30px 0px 0px 0px',
            width: '240px',
            height: '62px',
        },
        bottom_div: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
            gap: '10px',
        },
        bottom_feedback_div: {
            width: '100%',
            height: '32px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        bottom_feedback_txt: {
            fontSize: '18px',
            fontWeight: '500',
            color: theme.Wcolor.white,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginLeft: '10px',
        },
        bottom_profile_div: {
            width: '100%',
            height: '28px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        bottom_profile_txt: {
            fontSize: '28px',
            fontWeight: '500',
            color: theme.Wcolor.white,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginLeft: '10px',
        }
    });