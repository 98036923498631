// React 및 주요 라이브러리
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, FormControlLabel, Modal, Switch, TextField, ToggleButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { chatInfoAtom, clientInfoAtom } from '../recoilAtoms';


// 로컬 스타일 및 이미지 파일
import { styles } from './modal.style';
import modal_fixed_pin from '@common/assets/image/modal_fixed_pin.svg';
import modal_fixed_info from '@common/assets/image/modal_fixed_info.svg';

// api
import { apiChatlistFixedprompt } from '@services/api/chatlist';
import { fetchChatlistItem, fetchCustomer } from './apiServices';

type ModalPageProps = {
  modalOpen: boolean;
  handleModalClose: () => void;
  handleModalGenerateImagePrompt: (idx: number, text: string) => Promise<void>;
  modalSelectedImage: string | null;
  modalPrompt: string;
  modalLlmres: string;
  modalIdx: number;
  handleModalChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  chatid: string;
  chatIdHistory: string;
};

const ModalPage: React.FC<ModalPageProps> = ({
  modalOpen,
  handleModalClose,
  handleModalGenerateImagePrompt,
  modalSelectedImage,
  modalPrompt,
  modalLlmres,
  modalIdx,
  handleModalChange,
  chatid,
  chatIdHistory,
}) => {
  const classes = makeStyles(styles)();
  const [checked, setChecked] = useState(false);
  const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
  const [chatInfo, setChatInfo] = useRecoilState(chatInfoAtom);

  const [sexExists, setSexExists] = useState(false);
  const [sexApp, setSexApp] = useState("");
  useEffect(() => {
    const fetchInit = async () => {
      const customerChatId = await fetchCustomer(clientInfo.sid, chatIdHistory);
      const chatInfoData = await fetchChatlistItem(clientInfo.sid, customerChatId);
      if (chatInfoData.appearance == "") {
        setSexExists(false)
      } else {
        const parsedLlmres: Record<string, string> = JSON.parse(chatInfoData.appearance);
        const sex = parsedLlmres["sex"] || ""
        if (sex && sex != 'None') {
          setSexExists(true)
          setSexApp(sex)
        } else {
          setSexExists(false)
        }
      }
    };
    fetchInit();
  }, []);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [fixedPrompt, setFixedPrompt] = useState('');
  const handleMFixedPromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFixedPrompt(event.target.value);
  };

  const sex_title = 'Sex'
  const [sexContent, setsexContent] = useState('');
  const handleSetsexContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsexContent(event.target.value);
  };

  const posture_title = 'Posture'
  const [postureContent, setPostureContent] = useState('');
  const handleSetPostureContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostureContent(event.target.value);
  };

  const facial_title = 'Facial Expression'
  const [facialContent, setFacialContent] = useState('');
  const handleSetFacialContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFacialContent(event.target.value);
  };

  const props_title = 'Props'
  const [propContent, setPropsContent] = useState('');
  const handleSetPropsContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPropsContent(event.target.value);
  };

  const location_title = 'Location'
  const [locationContent, setLocationContent] = useState('');
  const handleSetLocationContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationContent(event.target.value);
  };

  const nsfw_title = 'NSFW'
  const [nsfwContent, setNSFWContent] = useState('');
  const handleSetNSFWContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNSFWContent(event.target.value);
  };

  const etc_title = 'Etc'
  const [etcContent, setEtcContent] = useState('');
  const handleSetEtcContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEtcContent(event.target.value);
  };


  const handleClickGenButton = () => {
    if (checked) {
      handleModalGenerateImagePrompt(modalIdx, modalPrompt)
    } else {
      const promTot = `{"fixedPrompt": "${fixedPrompt}", "task1": "${sexContent}", "task2": "${nsfwContent}", "task3": "${postureContent}", "task4": "${facialContent}", "task5": "${locationContent}", "task6": "${propContent}", "task7": "${etcContent}"}`
      handleModalGenerateImagePrompt(modalIdx, promTot)
    }

  }

  useEffect(() => {
    if (modalLlmres !== "") {
      const parsedLlmres: Record<string, string> = JSON.parse(modalLlmres);
      setsexContent(parsedLlmres["task1"] || "");
      setNSFWContent(parsedLlmres["task2"] || "");
      setPostureContent(parsedLlmres["task3"] || "");
      setFacialContent(parsedLlmres["task4"] || "");
      setPropsContent(parsedLlmres["task5"] || "");
      setLocationContent(parsedLlmres["task6"] || "");
      setEtcContent(parsedLlmres["task7"] || "");
    }
    fetchFixedprompt()
    if (sexExists) {
      setsexContent(sexApp)
    }
  }, [modalLlmres, sexExists]);

  const fetchFixedprompt = async () => {
    const customerChatId = await fetchCustomer(clientInfo.sid, chatIdHistory);
    const chatInfoData = await fetchChatlistItem(clientInfo.sid, customerChatId);
    setChatInfo(chatInfoData);
    setFixedPrompt(chatInfoData.fixedprompt)
  };

  const saveFixedprompt = async () => {
    await apiChatlistFixedprompt(clientInfo.sid, chatid, fixedPrompt);
  };

  return (

    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      disablePortal
      disableEnforceFocus
    >
      <Box
        className={classes.modalOverlay}
        onClick={handleModalClose}
      >
        <Box
          className={classes.modalContent1}
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            className={classes.modalContent2}
            onClick={(e) => e.stopPropagation()}
          >
            <Box className={classes.modalImageBox}>
              {modalSelectedImage && (
                <img
                  src={modalSelectedImage}
                  alt="Selected"
                  className={classes.modalImage}
                  loading="lazy"
                />
              )}
            </Box>
            <Box className={classes.toggleBox}>
              <Typography className={classes.toggleTitle}>Edit Full Prompt</Typography>
              <Switch
                className={classes.toggleButton}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickGenButton()}
              className={classes.genbutton}
              sx={{
                textTransform: 'none',
              }}
            >
              Generate
            </Button>
            <Box className={classes.modalSidebar}>
              {checked ? (
                <TextField
                  className={classes.modalTextField}
                  value={modalPrompt}
                  onChange={handleModalChange}
                  multiline
                  InputProps={{
                    style: {
                      color: 'white',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: 'white',
                    },
                  }}
                />
              ) : (
                <Box className={classes.partwrap}>
                  <Box className={classes.fixedwrap}>
                    <Box className={classes.fixedwrapfirst}>
                      <img
                        src={modal_fixed_pin}
                        style={{
                          height: '20px',
                          width: '20px',
                        }}
                      />
                      <Typography className={classes.fixedfirsttext}>Fixed Prompt</Typography>
                    </Box>
                    <Tooltip
                      title="Fixed Prompt is permanent prompt that’s always applied when describing this character. It’s a great tool to use when you’re not getting the results you want. Using short, clear keywords works best"
                      slotProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: '#2D2D2D',
                            color: '#fff',
                            fontSize: '14px',
                            padding: '10px 16px',
                            borderRadius: '4px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                          },
                        },
                      }}
                    >
                      <Box className={classes.fixedwrapfirst}>
                        <img
                          src={modal_fixed_info}
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                        />
                        <Typography className={classes.fixedsecondtext}>What is Fixed Prompt</Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box className={classes.fixedboxwrap}>
                    <TextField
                      className={classes.fixedboxtextfield}
                      value={fixedPrompt}
                      onChange={handleMFixedPromptChange}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                    <Button
                      onClick={() => saveFixedprompt()}
                      className={classes.fixedboxgenbutton}
                      sx={{
                        textTransform: 'none',
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                  <Box className={classes.textfieldblock}>
                    {sexExists ? (
                      <Typography className={classes.textfieldblocktitle}>{sex_title} (Fixed by Set Appearance)</Typography>
                    ) : (
                      <Typography className={classes.textfieldblocktitle}>{sex_title}</Typography>
                    )}
                    {sexExists ? (
                      <Typography
                        className={classes.textfieldblockfieldLock}
                      > {sexContent} </Typography>
                    ) : (
                      <TextField
                        className={classes.textfieldblockfield}
                        value={sexContent}
                        onChange={handleSetsexContent}
                        multiline
                        variant="outlined"
                        InputLabelProps={{
                          style: {
                            color: 'white',
                          },
                        }}
                      />
                    )}

                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{posture_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={postureContent}
                      onChange={handleSetPostureContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{facial_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={facialContent}
                      onChange={handleSetFacialContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{props_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={propContent}
                      onChange={handleSetPropsContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{location_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={locationContent}
                      onChange={handleSetLocationContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{nsfw_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={nsfwContent}
                      onChange={handleSetNSFWContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{etc_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={etcContent}
                      onChange={handleSetEtcContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { ModalPage };
